import React from 'react';

import { SimilarPosts } from '../components/SimilarPosts.component';
import {htmlToReactExcerpt} from '@helpers/html-to-react-excerpt';
import { createContents, htmlToReact } from '@helpers/html-to-react-converter';
import {AlgoliaMini} from '../../../shared/components/algolia-mini/AlgoliaMini.component';
import {PostShare} from '../../../shared/components/post-share/PostShare.component';
import {PostContainer} from '../../../shared/components/post-container/PostContainer.component';
import {PostHeader} from '../../../shared/components/post-header/PostHeader.component';
import {AllWpPostsWithSlug, IWpGuidePost, WpBanner} from '../../../../types/wordpress.types';
import {Banner} from '../../banner/Banner.component';
import {useIntl} from 'gatsby-plugin-intl';
import {getLink} from '@helpers/linking.helper';
import {PageType} from '@constants/pageType.constants';
import multilingualSlugs from '../../../../intl/slugs.json';
import {Breadcrumbs} from '../../../shared/components/breadcrumbs/Breadcrumbs.component';
import {GatsbyImage} from 'gatsby-plugin-image';
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import s from './GuidePost.module.scss';
import { gotoAnchor } from '../../../product/product.helper';
import ContentList from '../components/ContentList.component';

interface IGuidePost {
  guide: IWpGuidePost;
  categoriesPosts: AllWpPostsWithSlug;
  banner: WpBanner;
  isTopBarVisible: boolean;
}

const GuidePost = ({guide, banner, categoriesPosts}: IGuidePost) => {
  
  const intl = useIntl();

  const renderContents = () => {
    const arrayOfContent = createContents(guide.guideContent.guideBody);

    if(arrayOfContent && arrayOfContent.length>=1) {
      return ContentList(arrayOfContent);

    }
  };
  
  return (
    <>
      <AlgoliaMini isTopBarVisible={true}/>
      <PostContainer post={guide}>
        <Breadcrumbs areCrumbsCentered crumbs={[
          {
            name: intl.formatMessage({id: 'common__knowledgeBase'}),
            path: getLink(intl.locale, PageType.KNOWLEDGE_BASE)
          },
          {
            name: guide.categories.nodes[0].slug,
            path: getLink(intl.locale,
              PageType.KNOWLEDGE_BASE) + multilingualSlugs[intl.locale][PageType.CATEGORY] + '/' + guide.categories.nodes[0].slug
          },
          {
            name: guide.title,
            path: ''
          },
        ]}/>
        <PostHeader>{guide.title}</PostHeader>
        {htmlToReactExcerpt(guide.excerpt)}

        {guide.featuredImage &&
          <GatsbyImage alt={''} image={guide.featuredImage.node.localFile.childImageSharp.gatsbyImageData} className={s.guidePost__featuredImage}/>
        }
        {renderContents()}
        {htmlToReact(guide.guideContent.guideBody)}
        <PostShare author={guide.guideContent.author ? guide.guideContent.author[0] : null}
                   publishDate={guide.date}/>
        <Banner
          banner={banner}
        />
      </PostContainer>
      <SimilarPosts categoriesPosts={categoriesPosts} />
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export default GuidePost;
