import React from 'react';
import parse, {attributesToProps, domToReact, HTMLReactParserOptions} from 'html-react-parser';

import articleStyle from '../app/knowledge-base/+post/+article-post/ArticlePost.module.scss';
import slugify from 'slugify';

const rExp: RegExp = new RegExp('^https:\\/\\/avionaut\\.com\\.*')

enum NodeType {
  p = 'p',
  a = 'a',
  b = 'b',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  em = 'em',
  ul = 'ul',
  ol = 'ol',
  li = 'li',
  img = 'img',
  pre = 'pre',
  span = 'span',
  strong = 'strong',
  blockquote = 'blockquote',
}


const arry = [];

const options: HTMLReactParserOptions = {

  replace: ({attribs, name, children}) => {
    
    if (name === NodeType.ul) {
      return <ul key={attribs.key} style={{...attribs.style}} className={articleStyle.article__list}>{domToReact(children)}</ul>;
    }
    if (name === NodeType.ol) {
      return <ol key={attribs.key} style={{...attribs.style}} className={articleStyle.article__list}>{domToReact(children)}</ol>
    }
    if (name === NodeType.li) {
      return <li key={attribs.key} style={{...attribs.style}}>{domToReact(children)}</li>;
    }

    if (name === 'b' || name === 'strong') {
      return <strong key={attribs.key} style={{...attribs.style}} className={articleStyle.articlePost__b}>
        {domToReact(children)}
      </strong>;
    }
    
    if (name === 'a') {
      if (rExp.test(attribs.href)) {
        return <a
          key={attribs.key}
          style={{...attribs.style}}
          href={attribs.href}
          target="_blank"
          rel="noopener noreferrer"
          className={articleStyle.articlePost__link}>{domToReact(children)}</a>
      } else {
        return <a key={attribs.key}
                  style={{...attribs.style}}
                  href={attribs.href}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className={articleStyle.articlePost__link}>{domToReact(children)}</a>;
      }
    }

    if (name === 'h2') {
      const slug = slugify(domToReact(children).toString(), {lower: true});

      const entry = {
        title: domToReact(children),
        slug,
      };
      arry.push(entry)

      const props = attributesToProps(attribs);

      return <h2 {...props} id={slug} className={articleStyle.articlePost__middleHeader}>{domToReact(children)}</h2>;
    }
  }
}


export const htmlToReact = (html: string) => {
  return parse(html, options)
};

export const createContents = (html: string) => {
  const parsedHtml = parse(html, options);

  const contentsArray: {
    title: string;
    slug: string
  }[] = [];

  const results: { [x: string]: { type: string; props: {children: string} }; }[] = Object.keys(parsedHtml).map((key) => {
    return { [key]: parsedHtml[key as keyof typeof parsedHtml] };
  });

  results.forEach((result, index) => {
    if(result[index].type === 'h2'){
      const title = result[index].props.children;
      const slug = slugify(result[index].props.children,{lower: true});
      contentsArray.push({
        title,
        slug
      })
    }
  })

  return contentsArray;
}
