import s from './ContentList.module.scss';
import { gotoAnchor } from '../../../product/product.helper';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

const ContentList = (arrayOfContent: {title: string, slug: string}[]) => {
  const intl = useIntl();

  return (
    <div className={s.contentList}>
      <p className={s.contentList__title}>{intl.formatMessage({ id: 'post__contents' })}</p>
      <ul className={s.contentList__list}>
        {arrayOfContent.map((content) => {
          return (
            <li>
              <span className={s.contentList__point} onClick={()=>{gotoAnchor(content.slug)}}>{content.title}</span>
            </li>
          );
        })}
      </ul>
    </div>
  )
}

export default ContentList
